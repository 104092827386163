import * as React from "react";

import "./index.css";
import { useToast } from "@smartrr/vendor-portal/src/app/_sharedComponents/Toast/ToastProvider";
import { useIsSuperUserSelector } from "@smartrr/vendor-portal/src/app/_state/reducers/auth";
import { typedFrontendVendorApi } from "@smartrr/vendor-portal/src/utils/typedFrontendVendorApi";
export function AdminAnalyticsRoute(): JSX.Element {
  const { addToast } = useToast();
  const [embedUrl, setEmbedUrl] = React.useState<string>("");
  const isSuperUser = useIsSuperUserSelector();

  React.useEffect(() => {
    getUrl();
  }, []);

  const getUrl = async () => {
    try {
      const encodedEmbedUrl = await typedFrontendVendorApi.getReq("/looker-embed");

      if (encodedEmbedUrl.type === "error") {
        addToast(`Error getting analytics`);
        return;
      }
      setEmbedUrl(encodedEmbedUrl.body?.signedAndEncodedUrl);
    } catch (error) {
      if (isSuperUser) {
        console.log("Error:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div>
        {typeof embedUrl === "string" && embedUrl !== "" && (
          <iframe
            style={{ position: "absolute", margin: "auto" }}
            width="100%"
            height="100%"
            id="looker"
            src={embedUrl}
          ></iframe>
        )}
      </div>
    </React.Fragment>
  );
}
